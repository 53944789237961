<script lang="ts">
  import { onDestroy } from 'svelte'
  import { flip } from 'svelte/animate'
  import { atom, computed } from 'nanostores'
  import { Search } from 'carbon-components-svelte'
  import { dndzone } from 'svelte-dnd-action'
  import { state } from '../../../store/state'
  import { router } from '../../../routing/router'
  import { isThisKind } from '../../../routing/router-utils'
  import NothingToDisplay from '../../atoms/nothing-to-display.svelte'
  import SavedSearchDetails from './saved-search-details.svelte'

  const { localSearches } = state

  const text = atom('')
  const KEY = 'savedSearchFilter'

  const visibleSearches = computed([localSearches, text], (searches, text) => {
    return searches.filter((search) => search.name.toLowerCase().includes(text.toLowerCase()))
  })

  let updating = false
  let lastText = ''

  const doFilter = (value: string) => {
    if (value === lastText) {
      return
    }

    lastText = value
    if (value) {
      router(state)
        .upsertQueryParams({ [KEY]: value })
        .andStay()
    } else {
      router(state).deleteOneParam(KEY).andStay()
    }
  }

  const textUnsubscribe = text.subscribe(doFilter)

  const hashUnsubscribe = state.currentHash.subscribe(() => {
    if (!isThisKind('saved-search', state)) {
      return
    }

    if (updating) {
      return
    }

    try {
      updating = true
      const hasParam = router(state).route().hasParam(KEY)

      if (!hasParam) {
        return
      }

      const value = router(state).route().params[KEY]

      text.set(value)
    } finally {
      updating = false
    }
  })

  onDestroy(() => {
    textUnsubscribe && textUnsubscribe()
    hashUnsubscribe && hashUnsubscribe()
  })

  let edited = '(filtered)'

  let items = []
  let dropTargetStyle = {
    backgroundColor: 'var(--light-gray)',
  }

  $: {
    items = [...$visibleSearches]
  }

  const flipDurationMs = 300

  const handleDndConsider = (e: { detail: { items: any[] } }) => {
    items = e.detail.items
  }

  const handleDndFinalize = (e: { detail: { items: any[] } }) => {
    items = e.detail.items

    state.userData.savedSearches.setOrder(items.map((item) => item.id))
  }
</script>

<div class="saved-searches flex flex-col w-full h-full gap-4 px-10 pt-10 pb-4">
  <div class="flex flex-row gap-4">
    <h1 class="text-3xl font-bold">Saved searches</h1>
    <div class="text-[var(--dark-gray)] self-end" class:hidden={!$text.length}>{edited}</div>
  </div>
  <div class="max-w-[60%]">
    <Search bind:value={$text} />
  </div>
  <div class="flex w-full h-full gap-4 overflow-x-auto pb-4">
    <SavedSearchDetails />

    {#if $visibleSearches.length > 0}
      <section
        class="flex flex-row overflow-x-auto gap-4"
        use:dndzone={{ items, flipDurationMs, dropTargetStyle }}
        on:consider={handleDndConsider}
        on:finalize={handleDndFinalize}
      >
        {#each items as data, index (data.id)}
          <div animate:flip={{ duration: flipDurationMs }}>
            <SavedSearchDetails {data} index={index + 2} />
          </div>
        {/each}
      </section>
    {/if}

    {#if $visibleSearches.length === 0 && $localSearches.length > 0}
      <NothingToDisplay text="No saved searches match filter" />
    {/if}
    {#if $localSearches.length === 0}
      <NothingToDisplay text="You don't have any saved search" />
    {/if}
  </div>
</div>
